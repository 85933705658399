import React, { useEffect, useState, useRef } from "react";
import DebateGroupsComponent from '../components/DebateGroupsComponent';
import {
    getDebateGroupsService,
    createDebateGroupService,
    createDebateGroupAPI,
    archiveDebateGroupService,
    updateDebateGroupService,
    updateDebateGroupDataAPI,
    chatLoginService
} from 'services/chat';
import { getUsersService } from 'services/user';
import _ from 'lodash';
import moment from "moment";
import { getUploadCoursePictureService, uploadCoursePictureService } from 'services/courses';
import { getFileBuffer } from "utils/imageFormatter";


const DebateGroups = (props) => {
    const [groups, setGroups] = useState([]);
    const [groupsFetching, setGroupsFetching] = useState(false);
    const [submitGroupLoading, setSubmitGroupLoading] = useState(false);
    const [deleteGroupLoading, setDeleteGroupLoading] = useState(false);
    const [isManageGroupModalOpen, setIsManageGroupModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [usersList, setUsersList] = useState([])
    const [usersSelected, setUsersSelected] = useState([]);
    const reactQuillRef = useRef(null);

    const uploadFile = async (file) => {

        const urlToUpload = await getUploadCoursePictureService({ key: file.name, mimeType: file.type });

        const fileBuffer = await getFileBuffer(file)

        const response = await uploadCoursePictureService({ url: urlToUpload.url, mimeType: file.type, body: fileBuffer })

        let finalUrl = response?.url?.split('?')[0];

        return finalUrl;
    }
    useEffect(() => {
        getDebateGroups();
        chatLoginService();
        getUsers();
    }, [])

     //useEffect(() => {
         //console.log("GROUP", selectedGroup);
    //}, [selectedGroup])

    const getUsers = async () =>{
        const usersDB = await getUsersService();
        const users = usersDB.map(u => {return {value: u.profileId, label:`${u.name} ${u.surname}`}});
        setUsersList(users);
    }

    const getDebateGroups = async () => {
        setGroupsFetching(true);

        try {
            const groups = await getDebateGroupsService();
            //const groupsSortedByName = _.orderBy(groups, (debate) => debate.name.toLowerCase(), ['asc']);
            //setGroups(groupsSortedByName);

            groups.sort(function(a, b) {

                if(b.active != a.active) {
                    return b.active - a.active;
                } else {
                    return b.timestamp-a.timestamp > 0 ? 1 : -1
                }

            });

            setGroups(groups);
        } catch (err) {
            console.log(err);
        }

        setGroupsFetching(false);
    }

    const submitGroup = async (formValues, groupId) => {

        setSubmitGroupLoading(true);

        try {
            let data;

            if (groupId) {
                const groupIndex = groups.findIndex(group => group.id === groupId);

                const updatedGroupData = {
                    ...groups[groupIndex],
                    name: formValues.name,
                    description: formValues.description,
                    startDate: formValues.startDate,
                    endDate: formValues.endDate,
                    memberIds: formValues.participants,
                    photo: formValues?.picture?.url || 'https://via.placeholder.com/100'
                }
                const newParticipants = formValues.participants.filter(a=> groups[groupIndex].memberIds.indexOf(a) < 0)
                data = await updateDebateGroupService(updatedGroupData, groupId, newParticipants);
                updateDebateGroupDataAPI(data.id,formValues.name,formValues.description,formValues.participants,moment(formValues.startDate).format('YYYY-MM-DDTHH:mm:ss[Z]'),moment(formValues.endDate).format('YYYY-MM-DDTHH:mm:ss[Z]'));

                let newGroups = _.cloneDeep(groups);
                newGroups[groupIndex] = data;

                setGroups(newGroups);
            } else {
                const groupData = {
                    name: formValues.name,
                    description: formValues.description,
                    participants: formValues?.participants || [],
                    photo: formValues?.picture?.url || 'https://via.placeholder.com/100',
                    timestamp: +new Date(),
                    type: 'discussionGroup'
                }

                data = await createDebateGroupService(groupData);
                data.memberIds = formValues.participants

                await createDebateGroupAPI(data.id,formValues.name,formValues.description,formValues.participants,moment(formValues.startDate).format('YYYY-MM-DDTHH:mm:ss[Z]'),moment(formValues.endDate).format('YYYY-MM-DDTHH:mm:ss[Z]'));

                setGroups([data, ...groups]);
            }

            setIsManageGroupModalOpen(false);
            setSelectedGroup(null);
        } catch (err) {
            console.log(err)
        }

        setSubmitGroupLoading(false);
    }

    const deleteGroup = async (groupId) => {
        setDeleteGroupLoading(true);

        try {
            //await deleteDebateGroupService(groupId);
            await archiveDebateGroupService(groupId);
            //const groupsWithoutDeleted = groups.filter(group => group.id !== groupId);
            //setGroups(groupsWithoutDeleted);
            const groupsWithArchived = groups.map(group => group.id == groupId ? {...group, active: false} : group);
            setGroups(groupsWithArchived);
            setIsDeleteModalOpen(false);
            setSelectedGroup(null);
        } catch (err) {
            console.log(err);
        }

        setDeleteGroupLoading(false);
    }

    const checkCharacterCount = (event) => {
        const editor = reactQuillRef.current.getEditor();
        const unprivilegedEditor = reactQuillRef.current.makeUnprivilegedEditor(editor);

        if (unprivilegedEditor.getLength() > 500 && event.key !== 'Backspace')
            event.preventDefault();
    };



    return <DebateGroupsComponent
        groups={groups}
        groupsFetching={groupsFetching}
        submitGroup={submitGroup}
        deleteGroup={deleteGroup}
        submitGroupLoading={submitGroupLoading}
        deleteGroupLoading={deleteGroupLoading}
        setIsManageGroupModalOpen={setIsManageGroupModalOpen}
        isManageGroupModalOpen={isManageGroupModalOpen}
        setIsDeleteModalOpen={setIsDeleteModalOpen}
        isDeleteModalOpen={isDeleteModalOpen}
        selectedGroup={selectedGroup}
        setSelectedGroup={setSelectedGroup}
        usersSelected={usersSelected}
        setUsersSelected={setUsersSelected}
        usersList={usersList}
        checkCharacterCount={checkCharacterCount}
        reactQuillRef={reactQuillRef}
        uploadFile={uploadFile}
        />
}

export default DebateGroups;
