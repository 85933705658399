import React from "react";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import "./EventsComponent.scss";

const EventsComponent = (props) => {
    const { } = props;

    return (
        <DashboardLayout>
            <div className="events-wrapper">
                <iframe src={process.env.REACT_APP_EVENTIZA_URL} />
            </div>
        </DashboardLayout>
    );
};

export default EventsComponent;
