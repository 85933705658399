import React, { useState } from "react";
import "./DonationCertificateComponent.scss";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import MaterialInput from "components/elements/MaterialInput/MaterialInput";
import Button from "components/elements/Button/Button";
import { FaPlus } from 'react-icons/fa';
import { FiSearch } from 'react-icons/fi';
import { Formik, Form } from "formik";
import * as yup from "yup";
import Modal from 'react-modal';

const DonationCertificateComponent = (props) => {

    const { isManageCertificateModalOpen, setIsManageCertificateModalOpen, submitCertificateLoading, submitCertificate, getUrlFile, user, certificates, getCertificateById } = props;
    const [uploadingPicture, setUploadingPicture] = useState(false);

    const handlePictureUpload = async (file) => {
        setUploadingPicture(true)
        try {
            const url = await getUrlFile(file);
            return url;
        }
        catch (e) {
            console.log('e', e);
        }
        finally {
            setUploadingPicture(false)
        }
    }

    const getCertificate = async (id) => {
        const url = await getCertificateById(id);
        window.open(url, "_blank");
    }

    return (
        <DashboardLayout>
            <div className="courses-wrapper">
                <div className="section-title">
                    <h1>Certificados de donaciones de {user.name} {user.surname} </h1>
                    <Button className="secondary small" onClick={() => {
                        // setSelectedCourse({})
                        setIsManageCertificateModalOpen(true)
                    }}>
                        <FaPlus />
                        <span>Añadir certificado</span>
                    </Button>
                </div>


                <table>
                    <thead>
                        <tr>
                            <th>Año</th>
                            <th>Archivo</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            certificates.length ?
                                certificates.map((certificate, i) => (
                                    <tr key={i}>
                                        <td>{certificate.year}</td>
                                        <td>{certificate.file.key.match(/([^\/])*$/)[0]}</td>
                                        <td>
                                            <div onClick={()=>getCertificate(certificate.certificateId)} className="search-icon">
                                                <FiSearch />
                                            </div>
                                        </td>                                        
                                    </tr>
                                )) :

                                <div>No hay  certificados para mostrar.</div>
                        }
                    </tbody>
                </table>

                {/*certificates.length ?
                    certificates.map((certificate, i) => (
                        <div
                            key={i}>
                            {certificate.year} = <span onClick={()=>getCertificate(certificate.certificateId)}>Ver Certificado</span>
                        </div>
                    )) :
                    <div>No hay certificados para mostrar.</div>
                    */}
            </div>

            <Modal
                isOpen={isManageCertificateModalOpen}
                onRequestClose={() => setIsManageCertificateModalOpen(false)}
                className="arac-modal"
                overlayClassName="modal-overlay with-background"
                ariaHideApp={false}>
                <div className="manage-course-modal__wrapper">
                    <div className="modal-header">
                        <div>Añadir certificado</div>
                    </div>

                    <Formik
                        enableReinitialize
                        initialValues={{
                            year: '',
                            picture: {}
                        }}
                        validationSchema={
                            yup.object().shape({
                                picture: yup.object().shape({
                                    url: yup.string().required(),
                                    name: yup.string(),
                                    mimeType: yup.string()
                                }),
                                year: yup.number().required(),
                            })
                        }
                        onSubmit={(values) => submitCertificate(values)}>
                        {(formik) => (
                            <Form>
                                <div className="modal-body">


                                    <div className="fill-3">
                                        <MaterialInput
                                            formik={formik}
                                            type="number"
                                            id="year"
                                            label="Año"
                                            placeholder="Escribe aquí el año del certificado" />
                                    </div>

                                    <div className=" fill-3">
                                        <MaterialInput
                                            formik={formik}
                                            type="pdf"
                                            id="picture"
                                            uploadFile={handlePictureUpload}
                                            loading={uploadingPicture}
                                        />
                                    </div>
                                </div>

                                <div className="modal-footer">
                                    <Button className="light" onClick={() => setIsManageCertificateModalOpen(false)}>Cancelar</Button>
                                    <Button type='submit' onClick={() => formik.handleSubmit()} loading={submitCertificateLoading}>Guardar</Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Modal>

        </DashboardLayout>
    );
};

export default DonationCertificateComponent;
